// extracted by mini-css-extract-plugin
export var accordion = "_competitor-module--accordion--060a0";
export var accordionContainer = "_competitor-module--accordion-container--4c082";
export var accordionImage = "_competitor-module--accordion-image--d7fb5";
export var content = "_competitor-module--content--8e7ef";
export var demo = "_competitor-module--demo--7b70d";
export var demoContainer = "_competitor-module--demo-container--d7251";
export var description = "_competitor-module--description--048f2";
export var divider = "_competitor-module--divider--5953b";
export var expansionPanelImage = "_competitor-module--expansion-panel-image--896ab";
export var gptBackground = "_competitor-module--gpt-background--72db9";
export var gptContainer = "_competitor-module--gpt-container--ebb12";
export var gptImage = "_competitor-module--gpt-image--fba1c";
export var headerImage = "_competitor-module--header-image--ea6e1";
export var headline = "_competitor-module--headline--fafcc";
export var hero = "_competitor-module--hero--7f0f9";
export var heroActions = "_competitor-module--hero-actions--df7c1";
export var heroBackground = "_competitor-module--hero-background--72996";
export var heroContainer = "_competitor-module--hero-container--be271";
export var image = "_competitor-module--image--a0bc9";
export var label = "_competitor-module--label--4f8c9";
export var logos = "_competitor-module--logos--e784f";
export var logosContainer = "_competitor-module--logos-container--d5935";
export var logosInnerContainer = "_competitor-module--logos-inner-container--15181";
export var productEngagementContainer = "_competitor-module--product-engagement-container--09290";
export var quotesContainer = "_competitor-module--quotes-container--3e6b9";
export var row = "_competitor-module--row--6a303";
export var stat = "_competitor-module--stat--52ccb";
export var statsRow = "_competitor-module--stats-row--b5099";
export var subtitle = "_competitor-module--subtitle--70929";
export var tagline = "_competitor-module--tagline--6be41";
export var title = "_competitor-module--title--f5534";
export var videoButton = "_competitor-module--video-button--43926";
export var zendeskQuote = "_competitor-module--zendesk-quote--23355";